<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>德育之窗</el-breadcrumb-item>
            <el-breadcrumb-item>行者追光</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <el-row :gutter="20"><!--栅格中每个组件的间距，20px-->
                <el-col :span="6"><!--占的比重，一共24-->
                    <el-button type="primary" @click="toAddPage()">添加行者追光</el-button>
                </el-col>
            </el-row>
            <!--------------------表格--------------------->
            <el-table :data="tableData" stripe border><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60px">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 + queryInfo.pageSize * (queryInfo.currentPage - 1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="updateTime" label="最后修改时间"></el-table-column>
                <el-table-column prop="times" label="浏览次数"></el-table-column>
                <el-table-column prop="" label="操作" width="250px">
                    <template slot-scope="scope">
                        <!--编辑-->
                        <el-button type="primary" size="mini" @click="editItem(scope.row)">编辑</el-button>
                        <!--删除-->
                        <el-button type="danger" size="mini" @click="removeItem(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--------------------分页组件--------------------->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="queryInfo.currentPage" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="queryInfo.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import '../../assets/quill-font.css';
export default {
    name: 'Notice',
    data() {
        return {
            tableData: [],
            queryInfo: {
                currentPage: 1,//当前页码
                pageSize: 10,//每页显示多少条
                type: 8
            },
            totalCount: 0
        }
    },
    mounted () {
        this.getList();
    },
    methods: {
        toAddPage() {
            this.$router.push({path:'/moralComposition/addPedestrian', query:{pageTitle:'添加行者追光'}})
        },
        editItem(rows) {
            this.$router.push({path:'/moralComposition/addPedestrian',query:{pageTitle:'编辑行者追光',id:rows.id}})
        },
        async getList() {
            const { data: res } = await this.$http.post('/activity/list', this.queryInfo)
            if (res.code !== 200) {
                this.$message.error(res.message)
            } else {
                this.tableData = res.result.items
                this.totalCount = res.result.totalCount
            }
        },
        // 监听每页显示多少条的改变
        handleSizeChange (newSize) {
            this.queryInfo.pageSize = newSize
            this.getList()
        },
        // 监听选择第了几页
        handleCurrentChange (newPage) {
            this.queryInfo.currentPage = newPage
            this.getList()
        },
        //删除
        removeItem(rows) {
            this.$confirm('此操作将永久删除该条数据', '确认要删除吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let {data: res} = await this.$http({
                            url: '/activity/delete',
                            params: {id: rows.id},
                            headers:{
                                'Content-Type':"application/x-www-form-urlencoded",
                            },
                            method:'delete'
                        });
                        if (res.code === 200) {
                            done();
                            instance.confirmButtonLoading = false;
                            this.$message.success(res.message)
                            await this.getList()
                        } else {
                            this.$message.error(res.message)
                            instance.confirmButtonLoading = false;
                            done();
                        }
                    } else {
                        instance.confirmButtonLoading = false;
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
    }
}
</script>

<style scoped>
.el-pagination {
    margin-top: 15px;
}
</style>
